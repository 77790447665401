import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingRule } from '@dougs/accounting-review/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class AccountingRulesHttpService {
  constructor(private readonly http: HttpClient) {}

  getAccountingRules(
    companyId: number,
    accountingYearId: number,
    accountNumber?: string,
  ): Observable<AccountingRule[]> {
    let params: HttpParams = new HttpParams();
    if (accountNumber) {
      params = params.append('accountingNumber', accountNumber);
    }
    return this.http.get<AccountingRule[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/accounting-rules`,
      { params },
    );
  }

  updateAccountingRule(accountingRule: AccountingRule): Observable<AccountingRule> {
    let params: HttpParams = new HttpParams();
    if (accountingRule.accountingNumber) {
      params = params.append('accountingNumber', accountingRule.accountingNumber);
    }
    return this.http.post<AccountingRule>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingRule.companyId}/accounting-years/${accountingRule.accountingYearId}/accounting-rules/${accountingRule.id}`,
      { ...accountingRule, resultId: accountingRule.result?.id },
      {
        params,
      },
    );
  }
}
