import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import {
  AccountingAutoReview,
  AccountingAutoReviewError,
  AccountingLedger,
  AccountingLedgerLock,
  AccountingLedgerReviews,
  AccountingLedgerSearch,
  LedgerStats,
} from '@dougs/accounting-review/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { Comment, CommentPost } from '@dougs/task/dto';
import { AccountingLedgersHttpService } from '../http/accounting-ledgers.http';
import { LedgerFileService } from '../services/ledger-file.service';

interface LedgerState {
  ledgers: AccountingLedger[];
  stats: LedgerStats;
}

@Injectable({
  providedIn: 'root',
})
export class LedgerStateService extends StateService<LedgerState> {
  accountingLedgers$: Observable<AccountingLedger[]> = this.select((state) => state.ledgers);
  accountingLedgersCanBeAutomaticallyReview$: Observable<AccountingLedger[]> = this.select(
    (state) => state.ledgers?.filter((ledger) => ledger.canBeAutoReview) || [],
  );
  accountingLedgersStats$: Observable<LedgerStats> = this.select((state) => state.stats);

  constructor(
    private readonly accountingLedgersHttpService: AccountingLedgersHttpService,
    private readonly ledgerFileService: LedgerFileService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshLedgers(
    accountingYear: AccountingYear,
    search?: Partial<AccountingLedgerSearch>,
    force?: boolean,
  ): Promise<void> {
    try {
      const ledgers: AccountingLedger[] =
        this.getAndSetCacheState('ledgers', {
          companyId: accountingYear.companyId,
          accountingYearId: accountingYear.id,
          search,
        }) && !force
          ? this.state.ledgers
          : await lastValueFrom(
              this.accountingLedgersHttpService.getLedgers(accountingYear.companyId, accountingYear.id, search),
            );
      this.setState({ ledgers });
    } catch (e) {
      this.clearCache('ledgers');
      this.logger.error(e);
    }
  }

  async refreshLedgersStats(accountingYear: AccountingYear): Promise<void> {
    try {
      const stats: LedgerStats = await lastValueFrom(
        this.accountingLedgersHttpService.getLedgersStats(accountingYear.companyId, accountingYear.id),
      );
      this.setState({
        stats,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshLedger(ledger: AccountingLedger): Promise<void> {
    try {
      const ledgerUpdated: AccountingLedger = await lastValueFrom(
        this.accountingLedgersHttpService.getLedger(ledger.companyId, ledger.accountingYearId, ledger.accountNumber),
      );
      // Add/Remove review merge manuellement plutôt que de call refreshLedger ?
      this.setState({
        ledgers: this.state.ledgers.map((ledgerIterated) =>
          ledgerIterated.accountNumber === ledger.accountNumber
            ? {
                ...ledgerUpdated,
                nextAccountNumber: ledgerIterated.nextAccountNumber,
                previousAccountNumber: ledgerIterated.previousAccountNumber,
              }
            : ledgerIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addReviews(
    ledgers: AccountingLedger[],
    type: 'revision' | 'supervision',
  ): Promise<AccountingLedgerReviews | null> {
    try {
      return await lastValueFrom(
        this.accountingLedgersHttpService.addReviews(
          ledgers[0].companyId,
          ledgers[0].accountingYearId,
          ledgers.map((ledger) => ledger.accountNumber),
          type,
        ),
      );
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async addReview(ledger: AccountingLedger, type: 'revision' | 'supervision'): Promise<boolean> {
    try {
      await lastValueFrom(
        this.accountingLedgersHttpService.addReview(
          ledger.companyId,
          ledger.accountingYearId,
          ledger.accountNumber,
          type,
        ),
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async removeReview(ledger: AccountingLedger, type: 'revision' | 'supervision'): Promise<boolean> {
    try {
      if ((type === 'revision' && !ledger.revision?.id) || (type === 'supervision' && !ledger.supervision?.id)) {
        return false;
      }
      await lastValueFrom(
        this.accountingLedgersHttpService.removeReview(
          ledger.companyId,
          ledger.accountingYearId,
          ledger.accountNumber,
          type === 'revision' ? ledger.revision?.id : ledger.supervision?.id,
        ),
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async lockLedger(ledger: AccountingLedger, lockDate: Date): Promise<boolean> {
    try {
      const ledgerLock: AccountingLedgerLock = await lastValueFrom(
        this.accountingLedgersHttpService.lockLedger(ledger.companyId, ledger.accountNumber, lockDate),
      );
      this.setState({
        ledgers: this.state.ledgers.map((ledgerIterated) =>
          ledgerIterated.accountNumber === ledger.accountNumber
            ? {
                ...ledger,
                lock: ledgerLock,
              }
            : ledgerIterated,
        ),
      });
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateLockLedger(ledger: AccountingLedger): Promise<boolean> {
    try {
      if (!ledger.lock) {
        return false;
      }
      await lastValueFrom(
        this.accountingLedgersHttpService.updateLockLedger(ledger.companyId, ledger.accountNumber, ledger.lock),
      );
      this.setState({
        ledgers: this.state.ledgers.map((ledgerIterated) =>
          ledgerIterated.accountNumber === ledger.accountNumber ? ledger : ledgerIterated,
        ),
      });
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async lockLedgers(ledgers: AccountingLedger[], lockDate: Date): Promise<boolean> {
    try {
      const ledgersAccountNumber: string[] = ledgers.map((ledger) => ledger.accountNumber);
      const ledgerLocks: AccountingLedgerLock[] = await lastValueFrom(
        this.accountingLedgersHttpService.lockLedgers(ledgers[0].companyId, ledgersAccountNumber, lockDate),
      );
      this.setState({
        ledgers: this.state.ledgers.map((ledgerIterated) => {
          if (ledgersAccountNumber.includes(ledgerIterated.accountNumber)) {
            const ledgerLock: AccountingLedgerLock | undefined = ledgerLocks.find(
              (lock) => lock.accountNumber === ledgerIterated.accountNumber,
            );

            if (ledgerLock) {
              return {
                ...ledgerIterated,
                lock: ledgerLock,
              };
            }
          }

          return ledgerIterated;
        }),
      });
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async updateLedgerComment(ledger: AccountingLedger, comment: Comment): Promise<void> {
    try {
      const commentUpdated: Comment = await lastValueFrom(
        this.accountingLedgersHttpService.updateLedgerComment(ledger, comment),
      );

      this.updateLedgerFromState({
        ...ledger,
        comments: ledger.comments.map((commentIterated) =>
          commentIterated.id === commentUpdated.id ? commentUpdated : commentIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async commentLedger(ledger: AccountingLedger, comment: CommentPost): Promise<void> {
    try {
      const commentUpdated: Comment = await lastValueFrom(
        this.accountingLedgersHttpService.commentLedger(ledger, comment),
      );

      this.updateLedgerFromState({
        ...ledger,
        comments: [...ledger.comments, commentUpdated],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadLedgerCommentAttachments(ledger: AccountingLedger, comment: Comment, files: FileList): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.accountingLedgersHttpService.uploadLedgerCommentAttachment(ledger, comment, file)),
        ),
      );
      this.updateLedgerFromState(this.ledgerFileService.addAttachmentsToLedgerComment(ledger, comment, attachments));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeLedgerCommentAttachment(ledger: AccountingLedger, attachment: Attachment): Promise<void> {
    try {
      await lastValueFrom(this.accountingLedgersHttpService.deleteLedgerCommentAttachment(ledger, attachment));
      this.updateLedgerFromState(this.ledgerFileService.removeAttachmentToLedgerComment(ledger, attachment));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async automaticReviewLedgers(ledgers: AccountingLedger[]): Promise<AccountingAutoReviewError[]> {
    try {
      if (ledgers.length) {
        const accountingAutoReview: AccountingAutoReview = await toPromise(
          this.accountingLedgersHttpService.automaticReviewLedgers(
            ledgers[0].companyId,
            ledgers[0].accountingYearId,
            ledgers,
          ),
        );

        for (const updatedLedger of accountingAutoReview.ledgers) {
          this.updateLedgerFromState(updatedLedger);
        }

        return accountingAutoReview.errors;
      }
      return [];
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  private updateLedgerFromState(updatedLedger: AccountingLedger): void {
    this.setState({
      ledgers: this.state.ledgers?.map((ledger: AccountingLedger) =>
        ledger.accountNumber === updatedLedger.accountNumber ? updatedLedger : ledger,
      ),
    });
  }
}
