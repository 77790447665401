import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DenialOfCertification } from '@dougs/accounting-review/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class DenialOfCertificationHttpService {
  constructor(private readonly http: HttpClient) {}
  getDenialOfCertification(companyId: number, accountingYearId: number): Observable<DenialOfCertification> {
    return this.http.get<DenialOfCertification>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/denial-of-certification-status`,
    );
  }

  setDenialOfCertification(
    companyId: number,
    accountingYearId: number,
    denyCertification: boolean,
    forceUpdate = false,
  ): Observable<{
    approved: boolean;
    code: '403' | '300';
    denialOfCertification: {
      sourceReasons: string[];
      denyCertification: boolean;
    };
  }> {
    return this.http.post<{
      approved: boolean;
      code: '403' | '300';
      denialOfCertification: {
        sourceReasons: string[];
        denyCertification: boolean;
      };
    }>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/actions/set-denial-of-certification`,
      {
        denyCertification,
        sourceReason: 'preparation-supervision-switch',
        forceUpdate,
      },
    );
  }
}
