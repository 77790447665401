import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaxSummary } from '@dougs/accounting-review/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class TaxSummaryHttpService {
  constructor(private readonly http: HttpClient) {}
  getTaxSummary(companyId: number, accountingYearId: number): Observable<TaxSummary> {
    return this.http.get<TaxSummary>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/tax-summary`,
    );
  }
}
