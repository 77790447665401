import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { TaxSummary } from '@dougs/accounting-review/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { TaxSummaryHttpService } from '../http/tax-summary.http';

interface TaxSummaryState {
  taxSummary: TaxSummary;
}

@Injectable({
  providedIn: 'root',
})
export class TaxSummaryStateService extends StateService<TaxSummaryState> {
  taxSummary$: Observable<TaxSummary> = this.select((state) => state.taxSummary);

  constructor(
    private readonly taxSummaryHttpService: TaxSummaryHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshTaxSummary(accountingYear: AccountingYear): Promise<void> {
    try {
      this.setState({
        taxSummary: await lastValueFrom(
          this.taxSummaryHttpService.getTaxSummary(accountingYear.companyId, accountingYear.id),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
