import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingLedger, AccountingLedgerSearch, AccountingLineSearch } from '@dougs/accounting-review/dto';
import { AppConfig } from '@dougs/core/config';
import { AccountingLine } from '@dougs/operations/dto';

@Injectable({
  providedIn: 'root',
})
export class AccountingLinesHttpService {
  constructor(private readonly http: HttpClient) {}
  getAccountingLines(
    ledger: AccountingLedger,
    maxDate: string,
    orderBy: [string, 'ASC' | 'DESC'][],
    search: Partial<AccountingLedgerSearch>,
    accountingLineSearch: Partial<AccountingLineSearch>,
    offset = 0,
    limit = 30,
  ): Observable<AccountingLine[]> {
    let params: HttpParams = new HttpParams().append('offset', offset).append('limit', limit);
    if (search) {
      Object.entries(search).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            params = params.append(key, val);
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    if (accountingLineSearch?.letteringStatus?.length) {
      params = params.append('q-letteringStatus', accountingLineSearch.letteringStatus.join(','));
    }
    if (accountingLineSearch?.supplierId) {
      params = params.append('q-supplierId', accountingLineSearch.supplierId);
    }
    if (accountingLineSearch?.customerId) {
      params = params.append('q-customerId', accountingLineSearch.customerId);
    }
    if (orderBy[0][0]) {
      // ORDER OF 'orderBy' params matter, if amount is passed first doesn't work...
      [...orderBy]
        .reverse()
        .forEach(
          (orderByValue: [string, 'ASC' | 'DESC']) => (params = params.append('orderBy', JSON.stringify(orderByValue))),
        );
    } else {
      params = params.append('maxDate', maxDate);
    }
    return this.http.get<AccountingLine[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${ledger.companyId}/accounting-years/${ledger.accountingYearId}/ledgers/${ledger.accountNumber}/accounting-lines`,
      { params },
    );
  }

  associateAccrualAccountingLines(ledger: AccountingLedger, accountingLines: AccountingLine[]): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${ledger.companyId}/accounting-years/${ledger.accountingYearId}/ledgers/${ledger.accountNumber}/accounting-lines/associate-accrual-accounting-lines`,
      {
        accountingLine: accountingLines,
      },
    );
  }

  dissociateAccrualAccountingLines(ledger: AccountingLedger, accountingLines: AccountingLine[]): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${ledger.companyId}/accounting-years/${ledger.accountingYearId}/ledgers/${ledger.accountNumber}/accounting-lines/dissociate-accrual-accounting-lines`,
      {
        accountingLine: accountingLines,
      },
    );
  }
}
