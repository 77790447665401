import { Injectable } from '@angular/core';
import { addMonths, format } from 'date-fns';
import { first, lastValueFrom, Observable } from 'rxjs';
import { AccountingLedger, AccountingLedgerSearch, AccountingLineSearch } from '@dougs/accounting-review/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { AccountingLine } from '@dougs/operations/dto';
import { AccountingLinesHttpService } from '../http/accounting-lines.http';

interface AccountingLineState {
  accountingLines: AccountingLine[];
}

@Injectable({
  providedIn: 'root',
})
export class AccountingLinesStateService extends StateService<AccountingLineState> {
  constructor(
    private readonly accountingLinesHttpService: AccountingLinesHttpService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  accountingLines$: Observable<AccountingLine[]> = this.select((state) => state.accountingLines ?? []);

  offset = 0;
  limit = 30;

  async refreshLedgerAccountingLines(
    activeLedger: AccountingLedger,
    orderBy: [string, 'ASC' | 'DESC'][],
    search: Partial<AccountingLedgerSearch>,
    accountingLineSearch: Partial<AccountingLineSearch>,
  ): Promise<void> {
    try {
      this.offset = 0;
      const maxDate: string = await this.getLedgerMaxDate(activeLedger.accountingYearId);
      const accountingLines: AccountingLine[] = await lastValueFrom(
        this.accountingLinesHttpService.getAccountingLines(
          activeLedger,
          maxDate,
          orderBy,
          search,
          accountingLineSearch,
          this.offset,
          this.limit,
        ),
      );
      this.setState({ accountingLines });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshLedgerAccountingLinesKeepingOffset(
    activeLedger: AccountingLedger,
    orderBy: [string, 'ASC' | 'DESC'][],
    search: Partial<AccountingLedgerSearch>,
    accountingLineSearch: Partial<AccountingLineSearch>,
  ): Promise<void> {
    try {
      const maxDate: string = await this.getLedgerMaxDate(activeLedger.accountingYearId);
      const accountingLines: AccountingLine[] = await lastValueFrom(
        this.accountingLinesHttpService.getAccountingLines(
          activeLedger,
          maxDate,
          orderBy,
          search,
          accountingLineSearch,
          0,
          (this.offset + 1) * this.limit,
        ),
      );
      this.setState({ accountingLines });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async loadMoreAccountingLines(
    activeLedger: AccountingLedger,
    orderBy: [string, 'ASC' | 'DESC'][],
    search: Partial<AccountingLedgerSearch>,
    accountingLineSearch: Partial<AccountingLineSearch>,
  ): Promise<void> {
    try {
      this.offset++;
      const maxDate: string | undefined = await this.getLedgerMaxDate(activeLedger.accountingYearId);
      const accountingLines: AccountingLine[] = await lastValueFrom(
        this.accountingLinesHttpService.getAccountingLines(
          activeLedger,
          maxDate,
          orderBy,
          search,
          accountingLineSearch,
          this.offset * this.limit,
          this.limit,
        ),
      );
      this.setState({ accountingLines: [...this.state.accountingLines, ...accountingLines] });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async associateAccrualAccountingLines(ledger: AccountingLedger, accountingLines: AccountingLine[]): Promise<boolean> {
    try {
      await toPromise(this.accountingLinesHttpService.associateAccrualAccountingLines(ledger, accountingLines));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async dissociateAccrualAccountingLines(
    ledger: AccountingLedger,
    accountingLines: AccountingLine[],
  ): Promise<boolean> {
    try {
      await toPromise(this.accountingLinesHttpService.dissociateAccrualAccountingLines(ledger, accountingLines));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  private async getLedgerMaxDate(accountingYearId: number): Promise<string> {
    const accountingYears: AccountingYear[] = await lastValueFrom(
      this.accountingYearStateService.accountingYears$.pipe(first()),
    );
    const accountingYear: AccountingYear | undefined = accountingYears.find(
      (accYear) => accYear.id === accountingYearId,
    );
    if (accountingYear) {
      const maxDate: string = accountingYear.liquidationDate
        ? format(new Date(accountingYear.closingDate), 'yyyy-MM-dd')
        : format(addMonths(new Date(accountingYear.closingDate), 1), 'yyyy-MM-dd');
      return maxDate;
    }
    return '';
  }

  resetLines(): void {
    this.setState({
      accountingLines: [],
    });
  }
}
