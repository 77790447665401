import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { CompletionReason, Visa } from '@dougs/accounting-review/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { VisaHttpService } from '../http/visa.http';

interface VisaState {
  visas: Visa[];
}

@Injectable({
  providedIn: 'root',
})
export class VisaStateService extends StateService<VisaState> {
  visas$: Observable<Visa[]> = this.select((state) => state.visas);

  constructor(
    private readonly visaHttpService: VisaHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshVisas(accountingYear: AccountingYear): Promise<void> {
    try {
      this.setState({
        visas: await lastValueFrom(this.visaHttpService.getVisas(accountingYear.companyId, accountingYear.id)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async applyVisa(accountingYear: AccountingYear, completionReason: CompletionReason): Promise<void> {
    try {
      this.setState({
        visas: await lastValueFrom(
          this.visaHttpService.applyVisa(accountingYear.companyId, accountingYear.id, completionReason),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
