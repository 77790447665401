import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BalanceSheetsEmailParts } from '@dougs/accounting-years/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class BalanceSheetsHttpService {
  constructor(private readonly http: HttpClient) {}
  getBalanceSheetsEmailParts(companyId: number, accountingYearId: number): Observable<BalanceSheetsEmailParts> {
    return this.http.get<BalanceSheetsEmailParts>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/actions/get-balance-sheets-email-parts`,
    );
  }

  sendBalanceSheetsEmail(companyId: number, accountingYearId: number): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/actions/send-balance-sheets-email`,
      {},
    );
  }
}
