import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { concatMap, map, Observable } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { AccountingYearStateService } from '@dougs/accounting-years/shared';
import { CompanyStateService } from '@dougs/company/shared';
import { RoutingService } from '@dougs/core/routing';
import { AccountingYearService } from '../services/accounting-year.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectToDefaultAccountingYearGuard {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly accountingYearStateService: AccountingYearStateService,
    private readonly accountingYearService: AccountingYearService,
    private readonly routingService: RoutingService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const accountingYearParam: string | undefined = route.firstChild?.params?.accountingYear;
    return this.companyStateService.activeCompanyIdChanged$.pipe(
      concatMap((company) => this.accountingYearStateService.refreshAccountingYears(company.id)),
      map((accountingYears) => {
        const isValidAccountingYear: boolean = accountingYears.some(
          (accountingYear) => accountingYear.id.toString() === accountingYearParam,
        );
        if (isValidAccountingYear) {
          return true;
        }

        const accountingYear: AccountingYear | null = this.getAccountingYearToRedirect(accountingYears);
        if (accountingYear) {
          return this.routingService.createUrl(['accounting-review', accountingYear.id.toString(), 'cycles']);
        }

        return false;
      }),
    );
  }

  private getAccountingYearToRedirect(accountingYears: AccountingYear[]): AccountingYear | null {
    return (
      this.accountingYearService.getFirstNotClosedAccountingYear(accountingYears) ??
      this.accountingYearService.getLastAccountingYear(accountingYears)
    );
  }
}
