import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ComplementaryClosingInformation } from '@dougs/accounting-review/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { ComplementaryClosingInformationHttpService } from '../http/complementary-closing-information.http';

@Injectable({
  providedIn: 'root',
})
export class ComplementaryClosingInformationState extends StateService<void> {
  constructor(
    private readonly closingInformationHttpService: ComplementaryClosingInformationHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async getClosingInformation(
    companyId: number,
    accountingYearId: number,
    complementaryClosingInformationType: string,
  ): Promise<ComplementaryClosingInformation | null> {
    try {
      return await lastValueFrom(
        this.closingInformationHttpService.getComplementaryClosingInformation(
          companyId,
          accountingYearId,
          complementaryClosingInformationType,
        ),
      );
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateClosingInformation(
    companyId: number,
    accountingYearId: number,
    complementaryClosingInformationType: string,
    complementaryClosingInformation: {
      [key: string]: number | boolean | string;
    },
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.closingInformationHttpService.updateComplementaryClosingInformation(
          companyId,
          accountingYearId,
          complementaryClosingInformationType,
          complementaryClosingInformation,
        ),
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
