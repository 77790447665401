// TODO Put it in the config from the back
export const PREDEFINED_MISCELLANEOUS = [
  {
    code: 'vatAdjustment',
    title: 'Rompu de TVA',
  },
  {
    code: 'vatEUAdjustment',
    title: 'Rompu de TVA Européenne',
  },
  {
    code: 'eu-vat-accountNumbers-to-detailed-eu-vat-accountNumbers',
    title: 're-affectation du solde des comptes de TVA européenne vers les comptes détaillés',
  },
  {
    code: 'transfer-411XXX-to-412XXX',
    title: 're-affectation du solde des comptes clients',
  },
  {
    code: 'transfer-445546-to-4455900030600',
    title: 're-affectation du solde des comptes de TVA au Royaume-Uni',
  },
  {
    code: 'vatOutsideEuAdjustment',
    title: 'Rompu de TVA Hors Europe',
  },
  {
    code: 'miscellaneous-transfer-marketplace-configuration',
    title: 'OD Correction TVA non applicable',
  },
];
