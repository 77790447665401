import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { AccountingYear, BalanceSheetsEmailParts } from '@dougs/accounting-years/dto';
import { LoggerService } from '@dougs/core/logger';
import { BalanceSheetsHttpService } from '../http/balance-sheets.http';

@Injectable({
  providedIn: 'root',
})
export class BalanceSheetsStateService {
  constructor(
    private readonly balanceSheetsHttpService: BalanceSheetsHttpService,
    private readonly logger: LoggerService,
  ) {}

  async getBalanceSheetsEmailParts(accountingYear: AccountingYear): Promise<BalanceSheetsEmailParts | null> {
    try {
      return await lastValueFrom(
        this.balanceSheetsHttpService.getBalanceSheetsEmailParts(accountingYear.companyId, accountingYear.id),
      );
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async sendBalanceSheetsEmail(accountingYear: AccountingYear): Promise<boolean> {
    try {
      await lastValueFrom(
        this.balanceSheetsHttpService.sendBalanceSheetsEmail(accountingYear.companyId, accountingYear.id),
      );

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }
}
