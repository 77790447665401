import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Comment } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class ObservationsAndReservesHttpService {
  constructor(private readonly http: HttpClient) {}
  updateObserveAndReserve(comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${comment.companyId}/accounting-years/${comment.modelId}/comments/${comment.id}`,
      comment,
    );
  }

  deleteObserveAndReserveAttachment(comment: Comment, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${comment.companyId}/accounting-years/${comment.modelId}/comments/${attachment.modelId}/attachments/${attachment.id}`,
    );
  }

  uploadObserveAndReserveAttachment(comment: Comment, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${comment.companyId}/accounting-years/${comment.modelId}/comments/${comment.id}/attachments?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  getObserveAndReserve(companyId: number, accountingYearId: number): Observable<Comment[]> {
    const params: HttpParams = new HttpParams()
      .append('$or$', '{"tags":{"$contains":[{"type":"observation"}]}}')
      .append('$or$', '{"tags":{"$contains":[{"type":"reserve"}]}}');
    return this.http.get<Comment[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/comments`,
      { params },
    );
  }
}
