import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompletionReason, Visa } from '@dougs/accounting-review/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class VisaHttpService {
  constructor(private readonly http: HttpClient) {}
  getVisas(companyId: number, accountingYearId: number): Observable<Visa[]> {
    return this.http.get<Visa[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/visas`,
    );
  }

  applyVisa(companyId: number, accountingYearId: number, completionReason: CompletionReason): Observable<Visa[]> {
    return this.http.post<Visa[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/actions/apply-visa`,
      { completionReason },
    );
  }
}
