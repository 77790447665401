// TODO Put it in the config from the back
export const REVISION_CYCLES = [
  {
    id: 'A',
    title: 'Régularité formelle',
    accountRanges: [
      {
        minAccountingNumber: 471000,
        maxAccountingNumber: 475999,
      },
      {
        minAccountingNumber: 478000,
        maxAccountingNumber: 479999,
      },
    ],
  },
  {
    id: 'B',
    title: 'Trésorerie / Finances',
    accountRanges: [
      {
        minAccountingNumber: 160000,
        maxAccountingNumber: 169999,
      },
      {
        minAccountingNumber: 450000,
        maxAccountingNumber: 459999,
      },
      {
        minAccountingNumber: 495000,
        maxAccountingNumber: 495999,
      },
      {
        minAccountingNumber: 500000,
        maxAccountingNumber: 599999,
      },
      {
        minAccountingNumber: 627000,
        maxAccountingNumber: 627999,
      },
      {
        minAccountingNumber: 653000,
        maxAccountingNumber: 653999,
      },
      {
        minAccountingNumber: 655000,
        maxAccountingNumber: 659999,
      },
      {
        minAccountingNumber: 660000,
        maxAccountingNumber: 669999,
      },
      {
        minAccountingNumber: 686100,
        maxAccountingNumber: 686199,
      },
      {
        minAccountingNumber: 686600,
        maxAccountingNumber: 686699,
      },
      {
        minAccountingNumber: 763000,
        maxAccountingNumber: 763999,
      },
      {
        minAccountingNumber: 764000,
        maxAccountingNumber: 764999,
      },
      {
        minAccountingNumber: 766000,
        maxAccountingNumber: 766999,
      },
      {
        minAccountingNumber: 767000,
        maxAccountingNumber: 767999,
      },
      {
        minAccountingNumber: 768000,
        maxAccountingNumber: 768999,
      },
      {
        minAccountingNumber: 769000,
        maxAccountingNumber: 769999,
      },
      {
        minAccountingNumber: 786650,
        maxAccountingNumber: 786659,
      },
    ],
  },
  {
    id: 'C',
    title: 'Achats / Fournisseurs',
    accountRanges: [
      {
        minAccountingNumber: 400000,
        maxAccountingNumber: 409999,
      },
      {
        minAccountingNumber: 601000,
        maxAccountingNumber: 601999,
      },
      {
        minAccountingNumber: 602000,
        maxAccountingNumber: 602999,
      },
      {
        minAccountingNumber: 604000,
        maxAccountingNumber: 604999,
      },
      {
        minAccountingNumber: 605000,
        maxAccountingNumber: 605999,
      },
      {
        minAccountingNumber: 607000,
        maxAccountingNumber: 607999,
      },
      {
        minAccountingNumber: 608000,
        maxAccountingNumber: 608999,
      },
      {
        minAccountingNumber: 609000,
        maxAccountingNumber: 609999,
      },
      {
        minAccountingNumber: 651000,
        maxAccountingNumber: 651999,
      },
      {
        minAccountingNumber: 658000,
        maxAccountingNumber: 658999,
      },
      {
        minAccountingNumber: 765000,
        maxAccountingNumber: 765999,
      },
    ],
  },
  {
    id: 'D',
    title: 'Charges externes',
    accountRanges: [
      {
        minAccountingNumber: 606000,
        maxAccountingNumber: 606999,
      },
      {
        minAccountingNumber: 610000,
        maxAccountingNumber: 619999,
      },
      {
        minAccountingNumber: 621000,
        maxAccountingNumber: 621999,
      },
      {
        minAccountingNumber: 622000,
        maxAccountingNumber: 622999,
      },
      {
        minAccountingNumber: 623000,
        maxAccountingNumber: 623999,
      },
      {
        minAccountingNumber: 624000,
        maxAccountingNumber: 624999,
      },
      {
        minAccountingNumber: 625000,
        maxAccountingNumber: 625999,
      },
      {
        minAccountingNumber: 626000,
        maxAccountingNumber: 626999,
      },
      {
        minAccountingNumber: 628000,
        maxAccountingNumber: 628999,
      },
      {
        minAccountingNumber: 629000,
        maxAccountingNumber: 629999,
      },
      {
        minAccountingNumber: 671300,
        maxAccountingNumber: 671399,
      },
      {
        minAccountingNumber: 671800,
        maxAccountingNumber: 671899,
      },
      {
        minAccountingNumber: 791100,
        maxAccountingNumber: 791199,
      },
    ],
  },
  {
    id: 'E',
    title: 'Ventes / Clients',
    accountRanges: [
      {
        minAccountingNumber: 410000,
        maxAccountingNumber: 419999,
      },
      {
        minAccountingNumber: 491000,
        maxAccountingNumber: 491999,
      },
      {
        minAccountingNumber: 654000,
        maxAccountingNumber: 654999,
      },
      {
        minAccountingNumber: 671400,
        maxAccountingNumber: 671499,
      },
      {
        minAccountingNumber: 681740,
        maxAccountingNumber: 681749,
      },
      {
        minAccountingNumber: 700000,
        maxAccountingNumber: 709999,
      },
      {
        minAccountingNumber: 730000,
        maxAccountingNumber: 739999,
      },
      {
        minAccountingNumber: 763100,
        maxAccountingNumber: 763199,
      },
      {
        minAccountingNumber: 781740,
        maxAccountingNumber: 781749,
      },
    ],
  },
  {
    id: 'F',
    title: 'Stocks et travaux en-cours',
    accountRanges: [
      {
        minAccountingNumber: 143000,
        maxAccountingNumber: 143999,
      },
      {
        minAccountingNumber: 300000,
        maxAccountingNumber: 399999,
      },
      {
        minAccountingNumber: 603000,
        maxAccountingNumber: 603999,
      },
      {
        minAccountingNumber: 681730,
        maxAccountingNumber: 681739,
      },
      {
        minAccountingNumber: 687300,
        maxAccountingNumber: 687399,
      },
      {
        minAccountingNumber: 710000,
        maxAccountingNumber: 719999,
      },
      {
        minAccountingNumber: 781730,
        maxAccountingNumber: 781739,
      },
      {
        minAccountingNumber: 787300,
        maxAccountingNumber: 787399,
      },
    ],
  },
  {
    id: 'G',
    title: 'Immobilisations',
    accountRanges: [
      {
        minAccountingNumber: 145000,
        maxAccountingNumber: 145999,
      },
      {
        minAccountingNumber: 200000,
        maxAccountingNumber: 299999,
      },
      {
        minAccountingNumber: 675000,
        maxAccountingNumber: 675999,
      },
      {
        minAccountingNumber: 681100,
        maxAccountingNumber: 681199,
      },
      {
        minAccountingNumber: 681600,
        maxAccountingNumber: 681699,
      },
      {
        minAccountingNumber: 686620,
        maxAccountingNumber: 686629,
      },
      {
        minAccountingNumber: 687100,
        maxAccountingNumber: 687199,
      },
      {
        minAccountingNumber: 687200,
        maxAccountingNumber: 687299,
      },
      {
        minAccountingNumber: 720000,
        maxAccountingNumber: 729999,
      },
      {
        minAccountingNumber: 751000,
        maxAccountingNumber: 751999,
      },
      {
        minAccountingNumber: 752000,
        maxAccountingNumber: 752999,
      },
      {
        minAccountingNumber: 753000,
        maxAccountingNumber: 753999,
      },
      {
        minAccountingNumber: 761000,
        maxAccountingNumber: 761999,
      },
      {
        minAccountingNumber: 762000,
        maxAccountingNumber: 762999,
      },
      {
        minAccountingNumber: 775000,
        maxAccountingNumber: 775999,
      },
      {
        minAccountingNumber: 781100,
        maxAccountingNumber: 781199,
      },
      {
        minAccountingNumber: 781600,
        maxAccountingNumber: 781699,
      },
      {
        minAccountingNumber: 786620,
        maxAccountingNumber: 786629,
      },
      {
        minAccountingNumber: 787200,
        maxAccountingNumber: 787299,
      },
    ],
  },
  {
    id: 'H',
    title: 'Personnel',
    accountRanges: [
      {
        minAccountingNumber: 142000,
        maxAccountingNumber: 142999,
      },
      {
        minAccountingNumber: 420000,
        maxAccountingNumber: 429999,
      },
      {
        minAccountingNumber: 430000,
        maxAccountingNumber: 439999,
      },
      {
        minAccountingNumber: 640000,
        maxAccountingNumber: 649999,
      },
      {
        minAccountingNumber: 691000,
        maxAccountingNumber: 691999,
      },
    ],
  },
  {
    id: 'I',
    title: 'État',
    accountRanges: [
      {
        minAccountingNumber: 440000,
        maxAccountingNumber: 449999,
      },
      {
        minAccountingNumber: 630000,
        maxAccountingNumber: 639999,
      },
      {
        minAccountingNumber: 671200,
        maxAccountingNumber: 671299,
      },
      {
        minAccountingNumber: 671700,
        maxAccountingNumber: 671799,
      },
      {
        minAccountingNumber: 695000,
        maxAccountingNumber: 695999,
      },
      {
        minAccountingNumber: 696000,
        maxAccountingNumber: 696999,
      },
      {
        minAccountingNumber: 697000,
        maxAccountingNumber: 697999,
      },
      {
        minAccountingNumber: 698000,
        maxAccountingNumber: 698999,
      },
      {
        minAccountingNumber: 699000,
        maxAccountingNumber: 699999,
      },
      {
        minAccountingNumber: 740000,
        maxAccountingNumber: 749999,
      },
    ],
  },
  {
    id: 'J',
    title: 'Capitaux et provisions',
    accountRanges: [
      {
        minAccountingNumber: 100000,
        maxAccountingNumber: 109999,
      },
      {
        minAccountingNumber: 110000,
        maxAccountingNumber: 119999,
      },
      {
        minAccountingNumber: 120000,
        maxAccountingNumber: 129999,
      },
      {
        minAccountingNumber: 130000,
        maxAccountingNumber: 139999,
      },
      {
        minAccountingNumber: 140000,
        maxAccountingNumber: 149999,
      },
      {
        minAccountingNumber: 150000,
        maxAccountingNumber: 159999,
      },
      {
        minAccountingNumber: 170000,
        maxAccountingNumber: 179999,
      },
      {
        minAccountingNumber: 180000,
        maxAccountingNumber: 189999,
      },
      {
        minAccountingNumber: 681500,
        maxAccountingNumber: 681599,
      },
      {
        minAccountingNumber: 686500,
        maxAccountingNumber: 686599,
      },
      {
        minAccountingNumber: 687200,
        maxAccountingNumber: 687299,
      },
      {
        minAccountingNumber: 687400,
        maxAccountingNumber: 687499,
      },
      {
        minAccountingNumber: 687500,
        maxAccountingNumber: 687599,
      },
      {
        minAccountingNumber: 687600,
        maxAccountingNumber: 687699,
      },
      {
        minAccountingNumber: 777000,
        maxAccountingNumber: 777999,
      },
      {
        minAccountingNumber: 781500,
        maxAccountingNumber: 781599,
      },
      {
        minAccountingNumber: 786500,
        maxAccountingNumber: 786599,
      },
      {
        minAccountingNumber: 787400,
        maxAccountingNumber: 787499,
      },
      {
        minAccountingNumber: 787500,
        maxAccountingNumber: 787599,
      },
    ],
  },
  {
    id: 'K',
    title: 'Autres comptes',
    accountRanges: [
      {
        minAccountingNumber: 476000,
        maxAccountingNumber: 476999,
      },
      {
        minAccountingNumber: 477000,
        maxAccountingNumber: 477999,
      },
      {
        minAccountingNumber: 791000,
        maxAccountingNumber: 791000,
      },
      {
        minAccountingNumber: 791200,
        maxAccountingNumber: 791200,
      },
    ],
  },
];
