import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { DenialOfCertification } from '@dougs/accounting-review/dto';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { DenialOfCertificationHttpService } from '../http/denial-of-certification.http';

interface DenialOfCertificationState {
  denialOfCertification: DenialOfCertification;
}

@Injectable({
  providedIn: 'root',
})
export class DenialOfCertificationStateService extends StateService<DenialOfCertificationState> {
  denialOfCertification$: Observable<DenialOfCertification> = this.select((state) => state.denialOfCertification);

  constructor(
    private readonly denialOfCertificationHttpService: DenialOfCertificationHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshDenialOfCertification(accountingYear: AccountingYear): Promise<void> {
    try {
      this.setState({
        denialOfCertification: await lastValueFrom(
          this.denialOfCertificationHttpService.getDenialOfCertification(accountingYear.companyId, accountingYear.id),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async setDenialOfCertification(
    accountingYear: AccountingYear,
    denyCertification: boolean,
    forceUpdate = false,
  ): Promise<boolean> {
    try {
      const data: {
        approved: boolean;
        code: '403' | '300';
        denialOfCertification: {
          sourceReasons: string[];
          denyCertification: boolean;
        };
      } = await lastValueFrom(
        this.denialOfCertificationHttpService.setDenialOfCertification(
          accountingYear.companyId,
          accountingYear.id,
          denyCertification,
          forceUpdate,
        ),
      );

      if (!data.approved) {
        if (data.code === '403') {
          this.setState({
            denialOfCertification: {
              ...this.state.denialOfCertification,
              actualDenialOfCertification: {
                ...this.state.denialOfCertification.actualDenialOfCertification,
                denyCertification: !denyCertification,
              },
            },
          });
          return false;
        }
        return true;
      }
      this.setState({
        denialOfCertification: {
          ...this.state.denialOfCertification,
          actualDenialOfCertification: data.denialOfCertification,
        },
      });

      return false;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
