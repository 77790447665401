import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { SupervisionPreparationStatus } from '@dougs/accounting-review/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { SupervisionPreparationHttpService } from '../http/supervision-preparation.http';

interface SupervisionPreparationState {
  status: SupervisionPreparationStatus;
}

@Injectable({
  providedIn: 'root',
})
export class SupervisionPreparationStateService extends StateService<SupervisionPreparationState> {
  status$: Observable<SupervisionPreparationStatus> = this.select((state) => state.status);

  constructor(
    private readonly supervisionPreparationHttpService: SupervisionPreparationHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshSupervisionPreparationStatus(companyId: number, accountingYearId: number): Promise<void> {
    try {
      this.setState({
        status: await lastValueFrom(
          this.supervisionPreparationHttpService.getSupervisionPreparationStatus(companyId, accountingYearId),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
