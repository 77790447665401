import { Injectable } from '@angular/core';
import { AccountingYear } from '@dougs/accounting-years/dto';

@Injectable({
  providedIn: 'root',
})
export class AccountingYearService {
  getFirstNotClosedAccountingYear(accountingYears: AccountingYear[]): AccountingYear | null {
    const notClosedAccountingYears: AccountingYear[] = accountingYears.filter((accYear) => !accYear.closed);
    const minAccountingYearIndex: number = Math.min(
      ...notClosedAccountingYears.map((accYear) => accYear.accountingIndex),
    );
    const firstNotClosedAccountingYear: AccountingYear | undefined = accountingYears.find(
      (accYear) => accYear.accountingIndex === minAccountingYearIndex,
    );

    if (firstNotClosedAccountingYear) {
      return firstNotClosedAccountingYear;
    }

    return null;
  }

  getLastAccountingYear(accountingYears: AccountingYear[]): AccountingYear | null {
    const maxAccountingYearIndex: number = Math.max(...accountingYears.map((accYear) => accYear.accountingIndex));
    const lastAccountingYear: AccountingYear | undefined = accountingYears.find(
      (accYear) => accYear.accountingIndex === maxAccountingYearIndex,
    );

    return lastAccountingYear ?? null;
  }
}
