import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { AccountingYear } from '@dougs/accounting-years/dto';
import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Comment } from '@dougs/task/dto';
import { ObservationsAndReservesHttpService } from '../http/observations-and-reserves.http';

interface ObservationsAndReservesState {
  observeAndReserve: Comment[];
}

@Injectable({
  providedIn: 'root',
})
export class ObservationsAndReservesStateService extends StateService<ObservationsAndReservesState> {
  observations$: Observable<Comment[]> = this.select((state) =>
    state.observeAndReserve?.filter(
      (comment) =>
        comment.tags.some((tag) => tag.type === 'observation') && !comment.tags.some((tag) => tag.type === 'deleted'),
    ),
  );
  reserves$: Observable<Comment[]> = this.select((state) =>
    state.observeAndReserve?.filter(
      (comment) =>
        comment.tags.some((tag) => tag.type === 'reserve') && !comment.tags.some((tag) => tag.type === 'deleted'),
    ),
  );

  constructor(
    private readonly observationsAndReservesHttpService: ObservationsAndReservesHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshObserveAndReserve(accountingYear: AccountingYear): Promise<void> {
    try {
      this.setState({
        observeAndReserve: await lastValueFrom(
          this.observationsAndReservesHttpService.getObserveAndReserve(accountingYear.companyId, accountingYear.id),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateObserveAndReserve(comment: Comment): Promise<void> {
    try {
      const commentUpdated: Comment = await lastValueFrom(
        this.observationsAndReservesHttpService.updateObserveAndReserve(comment),
      );

      this.setState({
        observeAndReserve: this.state.observeAndReserve.map((commentIterated) =>
          commentIterated.id === commentUpdated.id ? commentUpdated : commentIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadObserveAndReserveAttachments(comment: Comment, files: FileList): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.observationsAndReservesHttpService.uploadObserveAndReserveAttachment(comment, file)),
        ),
      );
      this.setState({
        observeAndReserve: this.state.observeAndReserve.map((commentIterated) =>
          commentIterated.id === comment.id
            ? {
                ...comment,
                attachments: [...comment.attachments, ...attachments],
              }
            : commentIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeObserveAndReserveAttachment(comment: Comment, attachment: Attachment): Promise<void> {
    try {
      await lastValueFrom(
        this.observationsAndReservesHttpService.deleteObserveAndReserveAttachment(comment, attachment),
      );
      this.setState({
        observeAndReserve: this.state.observeAndReserve.map((commentIterated) =>
          commentIterated.id === comment.id
            ? {
                ...comment,
                attachments: comment.attachments.filter(
                  (attachmentIterated) => attachmentIterated.id !== attachment.id,
                ),
              }
            : commentIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
