import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Comment } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class PendingItemsHttpService {
  constructor(private readonly http: HttpClient) {}

  getComments(companyId: number, accountingYearId: number): Observable<Comment[]> {
    const params: HttpParams = new HttpParams()
      .append('orderBy', 'createdAt')
      .append('tags', '{"$contains":[{"type":"pendingItem"}]}');
    return this.http.get<Comment[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/comments`,
      { params },
    );
  }

  updatePendingItem(pendingItem: Comment): Observable<Comment> {
    return this.http.post<Comment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${pendingItem.companyId}/accounting-years/${pendingItem.modelId}/comments/${pendingItem.id}`,
      pendingItem,
    );
  }

  deletePendingItemAttachment(pendingItem: Comment, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${pendingItem.companyId}/accounting-years/${pendingItem.modelId}/comments/${attachment.modelId}/attachments/${attachment.id}`,
    );
  }

  uploadPendingItemAttachment(pendingItem: Comment, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${pendingItem.companyId}/accounting-years/${pendingItem.modelId}/comments/${pendingItem.id}/attachments?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  startAskCustomerForInformation(
    taskId: number,
    daysToDueDate: number,
    daysToReminder: number,
    filesId: number[],
    question: string,
    subject: string,
  ): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/tasks/${taskId}/actions/ask-customer-for-information`,
      {
        daysToDueDate,
        daysToReminder,
        filesId,
        question,
        subject,
      },
    );
  }
}
