import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AccountingAutoReview,
  AccountingLedger,
  AccountingLedgerLock,
  AccountingLedgerReview,
  AccountingLedgerReviews,
  AccountingLedgerSearch,
  LedgerStats,
} from '@dougs/accounting-review/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Comment, CommentPost } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class AccountingLedgersHttpService {
  constructor(private readonly http: HttpClient) {}

  getLedgers(
    companyId: number,
    accountingYearId: number,
    search?: Partial<AccountingLedgerSearch>,
  ): Observable<AccountingLedger[]> {
    let params: HttpParams = new HttpParams().append('offset', 0).append('limit', 2000);

    if (search) {
      Object.entries(search).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            params = params.append(key, val);
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }

    return this.http.get<AccountingLedger[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/ledgers`,
      { params },
    );
  }

  getLedger(companyId: number, accountingYearId: number, accountNumber: string): Observable<AccountingLedger> {
    return this.http.get<AccountingLedger>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/ledgers/${accountNumber}`,
    );
  }

  lockLedger(companyId: number, accountNumber: string, lockDate: Date): Observable<AccountingLedgerLock> {
    return this.http.post<AccountingLedgerLock>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accountingLocks`,
      {
        companyId,
        accountingNumber: accountNumber,
        lockDate,
      },
    );
  }

  updateLockLedger(
    companyId: number,
    accountNumber: string,
    accountingLedgerLock: AccountingLedgerLock,
  ): Observable<void> {
    return this.http.post<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accountingLocks/${accountNumber}`,
      accountingLedgerLock,
    );
  }

  lockLedgers(companyId: number, accountNumbers: string[], lockDate: Date): Observable<AccountingLedgerLock[]> {
    return this.http.post<AccountingLedgerLock[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accountingLocks/actions/bulk-update`,
      {
        accountingNumbers: accountNumbers,
        data: {
          lockDate,
        },
      },
    );
  }

  addReviews(
    companyId: number,
    accountingYearId: number,
    accountNumbers: string[],
    type: 'revision' | 'supervision',
  ): Observable<AccountingLedgerReviews> {
    return this.http.post<AccountingLedgerReviews>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/ledgers/actions/reviews`,
      {
        accountNumbers,
        type,
      },
    );
  }

  addReview(
    companyId: number,
    accountingYearId: number,
    accountNumber: string,
    type: 'revision' | 'supervision',
  ): Observable<AccountingLedgerReview> {
    return this.http.post<AccountingLedgerReview>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/ledgers/${accountNumber}/reviews`,
      {
        accountingNumber: accountNumber,
        type,
      },
    );
  }

  removeReview(
    companyId: number,
    accountingYearId: number,
    accountNumber: string,
    accountingLedgerReviewId?: number,
  ): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/ledgers/${accountNumber}/reviews/${accountingLedgerReviewId}`,
    );
  }

  getLedgersStats(companyId: number, accountingYearId: number): Observable<LedgerStats> {
    return this.http.get<LedgerStats>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/ledgers/actions/stats`,
    );
  }

  commentLedger(ledger: AccountingLedger, comment: CommentPost): Observable<Comment> {
    return this.http.post<Comment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${ledger.companyId}/accounting-years/${ledger.accountingYearId}/ledgers/${ledger.accountNumber}/comments`,
      comment,
    );
  }

  updateLedgerComment(ledger: AccountingLedger, comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${ledger.companyId}/accounting-years/${ledger.accountingYearId}/ledgers/${ledger.accountNumber}/comments/${comment.id}`,
      comment,
    );
  }

  deleteLedgerCommentAttachment(ledger: AccountingLedger, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${ledger.companyId}/accounting-years/${ledger.accountingYearId}/ledgers/${ledger.accountNumber}/comments/${attachment.modelId}/attachments/${attachment.id}`,
    );
  }

  uploadLedgerCommentAttachment(ledger: AccountingLedger, comment: Comment, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${ledger.companyId}/accounting-years/${ledger.accountingYearId}/ledgers/${ledger.accountNumber}/comments/${comment.id}/attachments?filename=${file.name}&type=attachment`,
      formData,
    );
  }

  automaticReviewLedgers(
    companyId: number,
    accountingYearId: number,
    ledgers: AccountingLedger[],
  ): Observable<AccountingAutoReview> {
    return this.http.post<AccountingAutoReview>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/ledgers/actions/auto-reviews`,
      { ledgers },
    );
  }
}
