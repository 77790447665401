import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { RoutingService, URL } from '@dougs/core/routing';
import { UserStateService } from '@dougs/user/shared';

@Injectable({
  providedIn: 'root',
})
export class CanAccessToAccountingReviewGuard {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly routingService: RoutingService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.userStateService.loggedInUser$.pipe(
      map((loggedInUser) => {
        if (loggedInUser.isAccountantOrAdmin) {
          return true;
        }

        return this.routingService.createUrl([URL.ACCOUNTING]);
      }),
    );
  }
}
