import { CompletionReason } from '@dougs/accounting-review/dto';

export const COMPLETION_REASONS: CompletionReason[] = [
  {
    code: 'approved',
    label: "Ok, j'applique mon Visa et je signe LAB-FT et Acceptation de mission",
    value: 'approved',
    messageRequired: false,
    messageTitle: '',
  },
  {
    label: "J'ai besoin d'informations complémentaires",
    value: 'additionalInformationRequested',
    code: 'additionalInformationRequested',
    messageRequired: true,
    messageTitle: "Merci d'indiquer les informations dont vous avez besoin",
  },
  {
    label: "Je refuse d'appliquer mon Visa",
    value: 'denied',
    code: 'denied',
    messageRequired: true,
    messageTitle: "Merci d'indiquer la raison du refus",
  },
];
