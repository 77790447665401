import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { catchError, lastValueFrom, map, Observable, tap } from 'rxjs';
import { AccountingLedger, AccountingRule } from '@dougs/accounting-review/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { AccountingRulesHttpService } from '../http/accounting-rules.http';

interface AccountingRuleState {
  accountingRules: AccountingRule[];
}

@Injectable({
  providedIn: 'root',
})
export class AccountingRuleStateService extends StateService<AccountingRuleState> {
  accountingRules$: Observable<AccountingRule[]> = this.select((state) => state.accountingRules);
  globalAccountingRules$: Observable<AccountingRule[]> = this.select((state) =>
    state.accountingRules.filter((rule) => rule.type === 'global'),
  );
  cycleAccountingRules$: Observable<AccountingRule[]> = this.select((state) =>
    state.accountingRules.filter((rule) => rule.type === 'cycle'),
  );
  localAccountingRules$: Observable<AccountingRule[]> = this.select((state) =>
    state.accountingRules.filter((rule) => rule.type === 'local'),
  );

  constructor(
    private readonly accountingRulesHttpService: AccountingRulesHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  refreshAccountingRules(
    companyId: number,
    accountingYearId: number,
    ledger?: AccountingLedger,
  ): Observable<AccountingRule[]> {
    return this.accountingRulesHttpService.getAccountingRules(companyId, accountingYearId, ledger?.accountNumber).pipe(
      map((rules: AccountingRule[]) =>
        _.uniqWith(rules, (a, b) => a.id === b.id && a.group === b.group && a.type === b.type),
      ),
      map((rules: AccountingRule[]) => {
        if (!ledger) {
          return rules;
        }

        return (
          this.state?.accountingRules?.map(
            (rule) =>
              rules.find((ruleIterated) => ruleIterated.id === rule.id && ruleIterated.group === rule.group) || rule,
          ) || rules
        );
      }),
      tap((accountingRules: AccountingRule[]) => this.setState({ accountingRules })),
      catchError((e) => {
        this.logger.error(e);
        this.setState({
          accountingRules: [],
        });
        return [];
      }),
    );
  }

  async updateAccountingRule(accountingRule: AccountingRule): Promise<void> {
    try {
      const updatedAccountingRule: AccountingRule = await lastValueFrom(
        this.accountingRulesHttpService.updateAccountingRule(accountingRule),
      );
      this.setState({
        accountingRules: this.state.accountingRules.map((accountingRuleIterated) =>
          accountingRuleIterated.id === accountingRule.id && accountingRuleIterated.group === accountingRule.group
            ? updatedAccountingRule
            : accountingRuleIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
