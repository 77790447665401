import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { Attachment } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Comment } from '@dougs/task/dto';
import { PendingItemsHttpService } from '../http/pending-items.http';

interface PendingItemsState {
  pendingItems: Comment[];
}

@Injectable({
  providedIn: 'root',
})
export class PendingItemsStateService extends StateService<PendingItemsState> {
  pendingItems$: Observable<Comment[]> = this.select((state) =>
    state.pendingItems.filter((pendingItem) => !pendingItem.tags.some((tag) => tag.type === 'deleted')),
  );

  constructor(
    private readonly pendingItemsHttpService: PendingItemsHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshPendingItems(companyId: number, accountingYearId: number): Promise<void> {
    try {
      this.setState({
        pendingItems: await lastValueFrom(this.pendingItemsHttpService.getComments(companyId, accountingYearId)),
      });
    } catch (e) {
      this.logger.error(e);
      this.setState({ pendingItems: [] });
    }
  }

  async updatePendingItem(pendingItem: Comment): Promise<void> {
    try {
      const pendingItemUpdated: Comment = await lastValueFrom(
        this.pendingItemsHttpService.updatePendingItem(pendingItem),
      );

      this.setState({
        pendingItems: this.state.pendingItems.map((pendingItemIterated) =>
          pendingItemIterated.id === pendingItemUpdated.id ? pendingItemUpdated : pendingItemIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadPendingItemAttachments(pendingItem: Comment, files: FileList): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) =>
          lastValueFrom(this.pendingItemsHttpService.uploadPendingItemAttachment(pendingItem, file)),
        ),
      );
      this.setState({
        pendingItems: this.state.pendingItems.map((pendingItemIterated) =>
          pendingItemIterated.id === pendingItem.id
            ? {
                ...pendingItem,
                attachments: [...pendingItem.attachments, ...attachments],
              }
            : pendingItemIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removePendingItemAttachment(pendingItem: Comment, attachment: Attachment): Promise<void> {
    try {
      await lastValueFrom(this.pendingItemsHttpService.deletePendingItemAttachment(pendingItem, attachment));
      this.setState({
        pendingItems: this.state.pendingItems.map((pendingItemIterated) =>
          pendingItemIterated.id === pendingItem.id
            ? {
                ...pendingItem,
                attachments: pendingItem.attachments.filter(
                  (attachmentIterated) => attachmentIterated.id !== attachment.id,
                ),
              }
            : pendingItemIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async startAskCustomerForInformation(
    taskId: number,
    daysToDueDate: number,
    daysToReminder: number,
    filesId: number[],
    question: string,
    subject: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.pendingItemsHttpService.startAskCustomerForInformation(
          taskId,
          daysToDueDate,
          daysToReminder,
          filesId,
          question,
          subject,
        ),
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
