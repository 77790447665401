import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ComplementaryClosingInformation } from '@dougs/accounting-review/dto';
import { AppConfig } from '@dougs/core/config';

@Injectable({
  providedIn: 'root',
})
export class ComplementaryClosingInformationHttpService {
  constructor(private readonly http: HttpClient) {}

  getComplementaryClosingInformation(
    companyId: number,
    accountingYearId: number,
    complementaryClosingInformationType: string,
  ): Observable<ComplementaryClosingInformation> {
    return this.http.get<ComplementaryClosingInformation>(
      `${
        AppConfig.settings.legacyApiServerUrl
      }/companies/${companyId}/accounting-years/${accountingYearId}/complementary-closing-information/${_.kebabCase(
        complementaryClosingInformationType,
      )}/form`,
    );
  }

  updateComplementaryClosingInformation(
    companyId: number,
    accountingYearId: number,
    complementaryClosingInformationType: string,
    complementaryClosingInformation: {
      [key: string]: number | boolean | string;
    },
  ): Observable<{
    [key: string]: number | boolean | string;
  }> {
    return this.http.post<{
      [key: string]: number | boolean | string;
    }>(
      `${
        AppConfig.settings.legacyApiServerUrl
      }/companies/${companyId}/accounting-years/${accountingYearId}/complementary-closing-information/${_.kebabCase(
        complementaryClosingInformationType,
      )}`,
      complementaryClosingInformation,
    );
  }
}
