import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Job } from '@dougs/core/job-dto';

@Injectable({
  providedIn: 'root',
})
export class GenerateFecHttpService {
  constructor(private readonly http: HttpClient) {}
  generateFec(companyId: number, accountingYearId: number): Observable<Job> {
    return this.http.post<Job>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/accounting-years/${accountingYearId}/actions/generate-fec-for-accounting-year`,
      {},
    );
  }
}
