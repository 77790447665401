import { Injectable } from '@angular/core';
import { AccountingLedger } from '@dougs/accounting-review/dto';
import { Attachment } from '@dougs/core/files';
import { Comment } from '@dougs/task/dto';

@Injectable({
  providedIn: 'root',
})
export class LedgerFileService {
  addAttachmentsToLedgerComment(
    ledger: AccountingLedger,
    comment: Comment,
    attachments: Attachment[],
  ): AccountingLedger {
    return {
      ...ledger,
      comments: ledger.comments.map((commentIterated) =>
        commentIterated.id === comment.id
          ? {
              ...commentIterated,
              attachments: [...commentIterated.attachments, ...attachments],
            }
          : commentIterated,
      ),
    };
  }

  removeAttachmentToLedgerComment(ledger: AccountingLedger, attachment: Attachment): AccountingLedger {
    return {
      ...ledger,
      comments: ledger.comments.map((commentIterated) =>
        commentIterated.id === attachment.modelId
          ? {
              ...commentIterated,
              attachments: commentIterated.attachments.filter(
                (attachmentIterated) => attachmentIterated.id !== attachment.id,
              ),
            }
          : commentIterated,
      ),
    };
  }
}
