import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { AccountingYear } from '@dougs/accounting-years/dto';
import { JobService } from '@dougs/core/job';
import { Job } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { GenerateFecHttpService } from '../http/generate-fec.http';

@Injectable({
  providedIn: 'root',
})
export class GenerateFecStateService {
  constructor(
    private readonly generateFecHttpService: GenerateFecHttpService,
    private readonly logger: LoggerService,
    private readonly jobService: JobService,
  ) {}

  async generateFec(accountingYear: AccountingYear): Promise<boolean> {
    try {
      const job: Job = await lastValueFrom(
        this.generateFecHttpService.generateFec(accountingYear.companyId, accountingYear.id),
      );

      await lastValueFrom(this.jobService.handleJob(job));

      return true;
    } catch (e) {
      this.logger.error(e);

      return false;
    }
  }
}
